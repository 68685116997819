import React from 'react';
import PropTypes from 'prop-types';

import { useSite } from '@unisporkal/sites';
import { useTranslations, useLocale } from '@unisporkal/localization';
import styles from './NotificationsList.module.scss';
import EmergencyBanner from '../../../models/EmergencyBanner';
import useHeaderPropsQuery from '../../../../hooks/queries/useHeaderPropsQuery';

const NotificationsList = ({ currentPage }) => {
  const site = useSite();
  const siteName = site.nickname;
  const containerStyles =
    siteName === 'istock' ? styles.istockContainer : styles.gettyContainer;
  const {
    data: { emergencyBannersData },
  } = useHeaderPropsQuery();
  const t = useTranslations();
  const locale = useLocale();
  const { enabledModes, customMessage, countryCode } = emergencyBannersData;
  const banner = new EmergencyBanner(
    enabledModes,
    customMessage,
    currentPage,
    countryCode,
    siteName
  );

  if (!banner.showBanner()) return null;

  const emergencyMessageKey = banner.messageIloxKey();
  const emergencyCustomMessage = banner.customMessage();

  return (
    <div className={containerStyles}>
      <aside
        className={styles.banner}
        aria-label="emergency message"
      >
        {emergencyMessageKey ? t(emergencyMessageKey) : null}
        {emergencyCustomMessage ? emergencyCustomMessage[locale] : null}
      </aside>
    </div>
  );
};

export default NotificationsList;

NotificationsList.propTypes = {
  currentPage: PropTypes.string.isRequired,
};
