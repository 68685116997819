import { useSuspenseQuery } from '@tanstack/react-query';
import { useSite } from '@unisporkal/sites';
import { QUERY_TYPE } from '../../actions/types';
import useServiceClient from '../useServiceClient';

const useHeaderPropsQuery = () => {
  const { headerDataService } = useServiceClient();
  const site = useSite();

  return useSuspenseQuery({
    queryKey: QUERY_TYPE.HEADER_DATA,
    queryFn: async () => {
      if (site.nickname === 'getty') {
        return headerDataService.fetchGettyHeaderData();
      }
      return headerDataService.fetchHeaderData();
    },
  });
};

export default useHeaderPropsQuery;
