import {
  gettyGeneralEmergencyModes,
  gettyPurchaseEmergencyModes,
  gettyPurchasePages,
  istockGeneralEmergencyModes,
  istockPurchaseEmergencyModes,
  istockPurchasePages,
} from './emergencyModes';

export default class EmergencyBanner {
  #emergencyModes;

  #bannerType;

  #showBanner;

  #generalEmergencyModes;

  #purchaseEmergencyModes;

  constructor(
    emergencyModes,
    customBanner,
    currentPage,
    countryCode,
    siteName
  ) {
    this.#generalEmergencyModes =
      siteName === 'istock'
        ? istockGeneralEmergencyModes
        : gettyGeneralEmergencyModes;
    this.#purchaseEmergencyModes =
      siteName === 'istock'
        ? istockPurchaseEmergencyModes
        : gettyPurchaseEmergencyModes;
    this.#emergencyModes = emergencyModes;
    this.#showBanner = emergencyModes?.length > 0;
    if (this.#showBanner) {
      this.customBanner = customBanner;
      const emergencyBanner = emergencyModes.find((mode) =>
        mode.includes(`${siteName}-banner`)
      );
      this.#bannerType = emergencyBanner
        ? emergencyBanner.substr(
            emergencyBanner.lastIndexOf('-') + 1,
            emergencyBanner.length
          )
        : null;
      this.#showBanner =
        this.#validCountry(countryCode, siteName) &&
        (this.#validMessage() ||
          this.#validPurchaseMessage(currentPage, siteName));
    }
  }

  showBanner() {
    return this.#showBanner;
  }

  customMessage() {
    return this.#showBanner && this.#bannerType === 'custom'
      ? this.customBanner
      : null;
  }

  messageIloxKey() {
    return this.#showBanner && this.#bannerType !== 'custom'
      ? `superstrip.${this.#bannerType}`
      : null;
  }

  bannerType() {
    return this.#bannerType;
  }

  #validCountry(countryCode, siteName) {
    const emergencyCountries = this.#emergencyModes
      .find((mode) => mode.includes(`${siteName}_country_list`))
      ?.replace(`${siteName}_country_list_start`, '')
      ?.replace(`${siteName}_country_list_end`, '');
    return (
      !emergencyCountries ||
      !emergencyCountries.length ||
      emergencyCountries.includes(countryCode)
    );
  }

  #validMessage() {
    const enabledModesWithMessages = this.#emergencyModes.filter((mode) =>
      this.#generalEmergencyModes.includes(mode)
    );
    return enabledModesWithMessages.length > 0;
  }

  #validPurchaseMessage(currentPage, siteName) {
    const purchasePages =
      siteName === 'istock' ? istockPurchasePages : gettyPurchasePages;
    const showPurchaseEmergencyMessage =
      purchasePages.includes(currentPage) &&
      this.#emergencyModes.filter((mode) =>
        this.#purchaseEmergencyModes.includes(mode)
      ).length > 0;

    return showPurchaseEmergencyMessage;
  }
}
