export const gettyGeneralEmergencyModes = [
  'getty-banner-maintenance',
  'getty-banner-outage',
  'getty-banner-custom',
];

export const gettyPurchaseEmergencyModes = [
  'getty-banner-getty_specific_cards',
  'getty-banner-getty_cards',
  'getty-banner-all_payment',
];

export const gettyPurchasePages = ['cart', 'checkout'];

export const istockGeneralEmergencyModes = [
  'istock-banner-maintenance',
  'istock-banner-outage',
  'istock-banner-custom',
];

export const istockPurchaseEmergencyModes = [
  'istock-banner-paypal',
  'istock-banner-istock_specific_cards',
  'istock-banner-istock_cards',
  'istock-banner-paypal_specific_cards',
  'istock-banner-all_payment',
];

export const istockPurchasePages = ['checkout', 'plans_and_pricing'];
